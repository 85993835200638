<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Teklif Bilgileri</span>
        </template>
        <validation-observer ref="simpleRules">
          <template v-if="basketLoading">
            <pre-loading is-alert />
          </template>
          <template v-else>
            <offer-form
              :submit-form="submitForm"
              :submit-status="submitStatus"
            />
          </template>
        </validation-observer>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="this.$route.params.id_customers" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import OfferForm from '@/views/Admin/Offers/OfferForm.vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'Add',
  components: {
    BTabs,
    BTab,
    OfferForm,
    CustomerInfo,
    CustomerInvoice,
    CustomerHistory,
    ValidationObserver,
    PreLoading,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    basketLoading() {
      return this.$store.getters['baskets/loading']
    },
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    saveData() {
      return this.$store.getters['offers/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$router.push(`/app/offers/view/${val.id}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('offers/RESET_DATA_ITEM')
    this.getRequestData()
    localize('tr')
  },
  methods: {
    getRequestData() {
      this.$store.dispatch('baskets/getDataView', this.$route.params.id_baskets)
        .then(response => {
          if (response) {
            this.getCustomer(response.id_customers)
            this.dataItem.id_customers = response.id_customers
            this.dataItem.id_customer_accounts = response.id_customer_accounts
            this.dataItem.id_baskets = this.$route.params.id_baskets
            this.setLines(response.items)
          }
        })
    },
    setLines(items) {
      items.forEach((item, index) => {
        this.dataItem.offer_lines.push({
          id: null,
          title: `${item.title} ${item.pcode}`,
          content: null,
          quantity: item.quantity * item.unit_multiplier,
          listed_price: 0,
          discounted_price: null,
          discount_amount: null,
          discount_rate: null,
          total_price: 0,
          ordering: index + 1,
          currency: null,
          tax_rate: 0,
          discountStatus: false,
          id_products: item.id_products,
          id_product_groups: item.id_product_groups,
          id_product_media: item.id_product_media ? item.id_product_media : 'is_batch',
          id_units: '2',
          id_currencies: null,
          id_taxes: '1',
        })
      })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/getDataItem', id)
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('offers/saveData', this.dataItem)
        } else {
          this.showToast({
            title: 'Uyarı',
            icon: 'InfoIcon',
            text: 'Lütfen tüm alanları doldurun.!',
            variant: 'warning',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
